import React from 'react';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {classes} from './Body.st.css';
import {CartItems} from '../../../../cart/Widget/Components/CartItems/CartItems';
import {Text} from 'wix-ui-tpa';

export enum BodyDataHooks {
  emptyState = 'BodyDataHooks.emptyState',
}

export const Body = () => {
  const {shouldRenderEmptyState} = useControllerProps().cartStore;

  return (
    <div className={classes.items}>
      {shouldRenderEmptyState ? (
        <div className={classes.emptyState}>
          <Text data-hook={BodyDataHooks.emptyState} className={classes.emptyStateText}>
            Your Cart is empty
          </Text>
        </div>
      ) : (
        <CartItems />
      )}
    </div>
  );
};
