import React from 'react';
import {useSettings} from '@wix/tpa-settings/react';
import settingsParams from '../../../../../common/settingsParams';
import {Text, TextPriority, IconButton} from 'wix-ui-tpa';
import {Close} from '@wix/wix-ui-icons-common/on-stage';
import {classes} from './Header.st.css';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';

export enum HeaderDataHooks {
  title = 'Header.Title',
  itemsCountText = 'Header.itemsCountText',
  closeButton = 'Header.closeButton',
}

export const Header = () => {
  const {get: getSettings} = useSettings();
  const {cart, shouldRenderEmptyState} = useControllerProps().cartStore;
  const {closeSideCart} = useControllerProps().navigationStore;

  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <Text tagName={'h2'} priority={TextPriority.primary} className={classes.headerText}>
          <Text data-hook={HeaderDataHooks.title} className={classes.headerTitle}>
            {getSettings(settingsParams.CART_TITLE) as string}
          </Text>
          &nbsp;
          {shouldRenderEmptyState ? null : (
            <Text data-hook={HeaderDataHooks.itemsCountText} className={classes.headerItemCount}>
              ({cart?.items.length} Items)
              {/*  TODO translation key */}
            </Text>
          )}
        </Text>
        <IconButton
          className={classes.closeButton}
          data-hook={HeaderDataHooks.closeButton}
          icon={<Close />}
          onClick={() => closeSideCart()}
        />
      </div>
    </div>
  );
};
