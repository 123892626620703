import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {ButtonPriority, Text} from 'wix-ui-tpa';
import {classes} from './Footer.st.css';
import {CheckoutButton} from '../../../../../common/components/CheckoutButton/CheckoutButton';
import {CartButton} from '../CartButton/CartButton';
import {SecureCheckout} from '../../../../../common/components/SecureCheckout/SecureCheckout';

export enum FooterDataHooks {
  root = 'Footer.root',
  totalLabel = 'Footer.totalLabel',
  totalValue = 'Footer.totalValue',
  totalDisclaimer = 'Footer.totalDisclaimer',
}

export const Footer = () => {
  const {t} = useTranslation();
  const {cart, shouldRenderEmptyState} = useControllerProps().cartStore;
  if (shouldRenderEmptyState) {
    return false;
  }

  return (
    <div className={classes.root} data-hook={FooterDataHooks.root}>
      <dl className={classes.totalRow}>
        <Text tagName={'dt'} data-hook={FooterDataHooks.totalLabel} className={classes.totalLabel}>
          {t('cart.estimated_total')}
        </Text>

        <Text tagName={'dd'} data-hook={FooterDataHooks.totalValue} className={classes.totalValue}>
          {cart.convertedTotals.formattedTotal}
        </Text>
      </dl>

      <div className={classes.disclaimer}>
        <Text data-hook={FooterDataHooks.totalDisclaimer} className={classes.disclaimerText}>
          {t('cart.tooltips.estimated_tax_message')}
        </Text>
      </div>
      <div className={classes.buttons}>
        <CheckoutButton priority={ButtonPriority.primary} />
        <CartButton />
      </div>
      <SecureCheckout />
    </div>
  );
};
